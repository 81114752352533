<template>
  <div>
    <div class="home">
      <img class="home_background" src="@/assets/banner-contact-us.jpg">
    </div>

    <div class="section_container">
      <div class="container">
        <div class="py-5 my-5 px-2">
          <h3>
            Email: <a href="mailto:brilcrist.sales@gmail.com">brilcrist.sales@gmail.com</a>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
